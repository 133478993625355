<template>
  <div class="element" :class="mapElement?.class">
    <div class="element-info map-mobile" :class="{ 'element-info__open': currentObject?.id === mapElement?.id }">
      <button class="element-caption map-mobile" @click="openCurrentObject">
        <div class="caption-btn" :class="{ 'caption-btn__open': currentObject?.id === mapElement?.id }">
          <img src="@/assets/icons/list-icon.svg" alt="" class="caption-btn-icon">
        </div>
        <p class="element-title title title-small ">{{ locale === 'en' ? mapElement?.name_eng : mapElement?.name }}</p>
      </button>
      <div class="element-content" :class="{ 'element-content__open': currentObject?.id === mapElement?.id }">
        <div class="element-demonstration-wrapper">
          <img :src="mapElement.imgUrl" alt="" class="element-demonstration">
        </div>

        <div class="element-filling">
          <div class="caption map-desktop">
            <p class="element-title title title-small ">{{
              locale === 'en' ? mapElement?.name_eng : mapElement?.name
            }}</p>
            <button class="close" @click="openCurrentObject">
              <img src="@/assets/icons/close-main.svg" alt="" class="close-icon">
            </button>
          </div>
          <p class="element-text text">{{
            locale === 'en' ? mapElement?.description_eng : mapElement?.description
          }}</p>
          <button v-if="currentObject?.id && typeof currentObject?.id === 'number' && !currentObject?.cantTeleportation"
            @click="teleportToLocation" class="link-default">{{ $t("game.controls.teleportToLocation") }}
          </button>
        </div>
      </div>
    </div>
    <button class="element-button map-desktop" @click="openCurrentObject"
      :class="{ 'element-button__open': currentObject?.id === mapElement?.id }">
      <img :src="mapElement.mapIcon" alt="" class="element-button-img ">
    </button>
  </div>
  <button class="place-btn" :class="{ 'place-btn__active': currentObject?.id === mapElement?.id }" :style="elementStyle"
    @click="openCurrentObject">
    <img src="@/assets/icons/game/position.svg" :style="imageStyle" alt="" class="element-button__img ">
  </button>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { computed, ref } from "vue";
import { appConfig } from "@/lib/appConfig";

export default {
  name: "MapElement",
  computed: {
    appConfig() {
      return appConfig
    }
  },
  emits: ['teleport',
    'openCurrentObject',],
  props: ['mapElement', 'currentObject', 'index', 'locale'],
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();

    const windowWidth = ref(window.innerWidth);

    window.onresize = () => {
      windowWidth.value = window.innerWidth;
    }

    const elementStyle = computed(() => {
      return {
        top: `${windowWidth.value <= 1500 ? props.mapElement?.media?.top : props.mapElement?.top}rem`,
        left: `${windowWidth.value <= 1500 ? props.mapElement?.media?.left : props.mapElement?.left}rem`,
        width: `${windowWidth.value <= 1500 ? props.mapElement?.media?.width : props.mapElement?.width}rem`,
        height: `${windowWidth.value <= 1500 ? props.mapElement?.media?.height : props.mapElement?.height}rem`,
        borderRadius: `${windowWidth.value <= 1500 ? props.mapElement?.media?.borderRadius : props.mapElement?.borderRadius}%`,
        transform: `rotate(${windowWidth.value <= 1500 ? props.mapElement?.media?.transform : props.mapElement?.transform}deg)`,
      }
    })

    const imageStyle = computed(() => {
      return {
        transform: `rotate(${windowWidth.value <= 1500 ? props.mapElement?.media?.transformImage : props.mapElement?.transformImage}deg)`,
        marginTop: `${windowWidth.value <= 1500 ? props.mapElement?.media?.marginTop : props.mapElement?.marginTop}rem`,
        marginBottom: `${windowWidth.value <= 1500 ? props.mapElement?.media?.marginBot : props.mapElement?.marginBot}rem`,
        marginLeft: `${windowWidth.value <= 1500 ? props.mapElement?.media?.marginLeft : props.mapElement?.marginLeft}rem`,
        marginRight: `${windowWidth.value <= 1500 ? props.mapElement?.media?.marginRight : props.mapElement?.marginRight}rem`,
      }
    })
    const teleportToLocation = () => {
      if (route.name !== 'gamePage') {
        // console.log(`TELEPORT RedesignAvailablePlaces.vue: ${props.mapElement?.id}`, props)
        sessionStorage.setItem('teleportId', props.mapElement?.id);
        router.push({ name: 'gamePage' });
      } else {
        // console.log(`TELEPORT RedesignAvailablePlaces.vue: ${props.mapElement?.id}`)
        // console.log(`%cteleport to mapElementId: ${props.mapElement?.id}, `, 'background: blue; font-size: 20px')
        emit('teleport', props.mapElement?.id);
      }
    }

    return {
      openCurrentObject() {
        emit('openCurrentObject', props.mapElement)
      },
      teleportToLocation,
      elementStyle,
      imageStyle

    }
  }
}
</script>

<style scoped>
.element {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.element-info {
  border-radius: 1rem;
  padding: 1.5rem;
  width: 42.25rem;
  background: #FFF;
  filter: drop-shadow(0px 10px 16px rgba(69, 94, 128, 0.32));

  opacity: 0;
  transform: translateY(10%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  z-index: -1;
  cursor: auto;
}

.element-content {
  display: flex;
  gap: 1.5rem;
}

.element-info__open {
  opacity: 1;
  transform: translateY(0%);
  z-index: 1;
}

.element-filling {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
}


.caption {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
}

.close {
  display: flex;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.element-text {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}


.link-default {
  margin-top: auto;
}

.element-button {
  margin-top: 2rem;
  margin-right: -2rem;

}

.element-demonstration-wrapper {
  position: relative;
  max-width: 13.75rem;
  width: 100%;
  border-radius: 0.5rem;
  height: 13.75rem;
  display: flex;
  flex-shrink: 0;
}

.element-demonstration {
  border-radius: 0.5rem;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;

}

.element-button-img {
  width: 100%;
}


.element-button {
  opacity: 0;
  transition: all 0.5s ease-in-out;
  transform: translateY(10%);

  cursor: pointer;
  z-index: 1;
}

.element-button:hover {
  transform: translateY(0%);
  opacity: 1;
}


.element-button__open {
  opacity: 1;
  transform: translateY(0%);
}

.map-mobile {
  display: none;
}

.place-btn:hover {
  opacity: 1;
}

.place-btn {
  position: absolute;
  border-radius: 100%;
  box-shadow: 0px 24px 24px rgba(53, 113, 122, 0.015);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.element-button__img {
  position: absolute;
  display: flex;
  flex-shrink: 0;
  max-width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.5s ease;
}


.place-btn img {
  opacity: 0;
  margin: 0;
  pointer-events: none;
}

.place-btn__active img {
  opacity: 1;
}

.place-btn:hover .element-button__img {
  opacity: 1;

}

@media (max-width: 1500px) {
  .element-demonstration-wrapper {
    max-width: 10.75rem;

    height: 10.75rem;
  }

  .element-title {
    font-size: 1.25rem;
  }

  .element-text {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .link-default {
    font-size: 0.875rem;
    padding: 0.75rem;
  }

}


/* Elements */
.element1 {
  right: 22rem;
  bottom: 12rem;
}

.element1+.place-btn {
  display: none;
}

.element2+.place-btn {
  display: none;
}

.element3+.place-btn {
  display: none;
}

.element4+.place-btn {
  display: none;
}

.element5+.place-btn {
  display: none;
}

.element6+.place-btn {
  display: none;
}

.element7+.place-btn {
  display: none;
}

.element8+.place-btn {
  display: none;
}

.element9+.place-btn {
  display: none;
}

.element10+.place-btn {
  display: none;
}

.element11+.place-btn {
  display: none;
}

.element12+.place-btn {
  display: none;
}

.element1 .element-button {
  width: 10.25rem;
  border-radius: 100%;
  left: -2rem;
  position: relative;
}

.element2 {
  top: 2rem;
  left: 18.31rem;
}

.element2 .element-button {
  width: 17.3125rem;
}

.element2 .element-info {
  width: 57rem;
}

.element3 {
  top: 25.2rem;
  left: 9.71rem;
}

.element3 .element-button {
  width: 10.313rem;
}

.element3 .element-info {
  width: 47rem;
}

.element4 {
  right: 1.6rem;
  bottom: -1rem;
}

.element4 .element-button {
  width: 15rem;
}

.element4 .element-info {
  width: 52rem;
}

.element5 {
  right: 1.9rem;
  bottom: 13.4rem;
  width: 14rem;
}

.element6 {
  right: 24.2rem;
  bottom: 19.5rem;
  width: 2.6rem;
}

.element7 {
  right: 47.6rem;
  bottom: 21rem;
  width: 2.1rem;
}

.element8 {
  right: 62.4rem;
  bottom: 29.5rem;
  width: 3rem;
}

.element9 {
  right: 33.4rem;
  bottom: 12.4rem;
  width: 2.8rem;
}

.element10 {
  right: 50.3rem;
  bottom: 32.6rem;
  width: 1.8rem;
}

.element11 {
  right: 64.3rem;
  bottom: 34.4rem;
  width: 10.4rem;
}

.element12 {
  right: 38.4rem;
  bottom: 35.76rem;
  width: 2.7rem;
}




@media (max-width: 1500px) {

  .element1 {
    right: 16rem;
    bottom: 9rem;
  }

  .element1 .element-button {
    width: 7.25rem;
  }

  .element2 {
    top: 1.5rem;
    left: 13.81rem;
  }

  .element2 .element-button {
    width: 12rem;
  }

  .element2 .element-info {
    width: 49rem;
  }

  .element3 {
    top: 18.5rem;
    left: 7rem;
  }

  .element3 .element-button {
    width: 8rem;
  }

  .element3 .element-info {
    width: 44rem;
  }

  .element4 {
    right: 1.6rem;
    bottom: -1rem;
  }

  .element4 .element-button {
    width: 11rem;
  }

  .element4 .element-info {
    width: 49rem;
  }

  .element5 {
    right: 2.5rem;
    bottom: 10.4rem;
    width: 9rem;
  }


  .element6 {
    right: 18.2rem;
    bottom: 14.4rem;
    width: 1.6rem;
  }

  .element7 {
    right: 35.4rem;
    bottom: 15.2rem;
    width: 1.3rem;
  }

  .element8 {
    right: 46.4rem;
    bottom: 21.5rem;
    width: 1.8rem;
  }

  .element9 {
    right: 25.4rem;
    bottom: 9rem;
    width: 1.4rem;
  }

  .element10 {
    right: 37.6rem;
    bottom: 24rem;
    width: .8rem;
  }

  .element11 {
    right: 48.1rem;
    bottom: 25rem;
    width: 7rem;
  }

  .element12 {
    right: 29rem;
    bottom: 26.2rem;
    width: 1.4rem;
  }

}

@media (max-width: 1000px) {
  .element {
    position: relative;
    left: unset !important;
    right: unset !important;
    top: unset !important;
    bottom: unset !important;
    z-index: 2;
  }

  .element-info {
    flex-direction: column;
  }

  .element .element-info {
    width: 100%;
  }

  .map-desktop {
    display: none;
  }

  .map-mobile {
    display: inherit;
  }

  .element-info {
    border-top: 1px solid #CAD4E1;
    border-radius: 0;
    opacity: 1;
    transform: translateY(0%);
    width: 100%;
    padding: 1rem 0;
    background: none;
    box-shadow: none;
  }

  .element-caption {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    max-width: 100%;
    width: 100%;
  }

  .caption-btn {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    flex-shrink: 0;
    transition: transform 0.5s ease-in-out;
    transform: rotate(180deg);
  }

  .caption-btn-img {
    width: 100%;
    height: 100%;
  }

  .caption-btn__open {
    transform: rotate(0deg);
  }

  .element-title {
    margin-top: 0;
    text-align: left;
  }

  .element-content {
    gap: 0;
    flex-direction: column;
    overflow: hidden;
    display: none;
  }

  .element-content__open {
    display: inherit;
    padding-top: 0.62rem;
  }

  .element-demonstration {
    max-width: 100%;
    width: 100%;
  }

  .element-text {
    margin: 1rem 0 1.5rem 0;
  }

  .element-demonstration-wrapper {
    max-width: 100%;
    height: auto;
  }

  .element-demonstration {
    position: relative;
  }

  .place-btn {
    display: none;
  }

}
</style>