<script setup>
import { useStore } from "vuex";
import { ref, computed, reactive, onMounted, onBeforeMount } from "vue";
import StarRatings from "vue3-star-ratings";

const store = useStore();

const showQuestionBoard = computed(() => store.getters.showQuestionBoard);

const questions = {
  level: "Какой раздел экскурсии понравился вам больше всего?",
  recommendation: "Порекомендовали бы вы прохождение экскурсии вашим друзьям?",
  likes: "Что не понравилось?",
  interference: "Что помешало прохождению экскурсии?",
};

const state = reactive({
  level: "",
  recommendation: "",
  likes: "",
  interference: "",
  rating: 0,
});

const pending = ref(false);

const openedQuestions = ref([]);

onMounted(() => {
  window.addEventListener("keydown", onKeyDownEscape);
});

onBeforeMount(() => {
  window.removeEventListener("keydown", onKeyDownEscape);
});

function closeTotal() {
  store.commit("setShowQuestionBoard", false);
}

function onKeyDownEscape(event) {
  if (event.key === "Escape") {
    closeTotal();
  }
}

function updateRating(rating) {
  state.rating = Math.ceil(rating);
}

function toggleQuestion(index) {
  if (openedQuestions.value.includes(index)) {
    openedQuestions.value = openedQuestions.value.filter((n) => n !== index);
  } else {
    openedQuestions.value = [...openedQuestions.value, index];
  }
}

function getListItemClasses(index) {
  return [
    'list-item',
    {
      'list-item--opened': openedQuestions.value.includes(index),
    }
  ]
}


async function onSave() {
  try {
    pending.value = true;
    const formData = new FormData();

    Object.entries(state).forEach(([key, value]) => {
      formData.append(key, value);
    });
    await store.dispatch('saveFeedback', formData);
    store.commit('setShowQuestionBoard', false);
  } finally {
    pending.value = false;
  }
}
</script>

<template>
  <Teleport to="body">
    <Transition name="fade">
      <div v-if="showQuestionBoard" class="question-board">
        <div class="modal">
          <h3 class="modal__title">Спасибо за обратную связь!</h3>
          <ul class="list">
            <li
              v-for="[key, value], index in Object.entries(questions)"
              :key="key"
              :class="getListItemClasses(index)"
              @click="toggleQuestion(index)"
            >
              <p class="list-item__title">
                {{ value }}
                <span>
                  <svg
                    width="27"
                    height="15"
                    viewBox="0 0 27 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                  >
                    <path
                      d="M3 3L14.5 14L26 3"
                      stroke="#393939"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </p>
              <div
                v-show="openedQuestions.includes(index)"
              >
                  <textarea v-model="state[key]" @click.stop />
              </div>
            </li>
          </ul>
          <div class="rate">
            Оцените полученный опыт от 1 до 5
            <StarRatings
              :starsCounts="5"
              :modelValue="state.rating"
              inactiveColor="#9dc4f8ab"
              starColor="#ffc200"
              @update:modelValue="updateRating"
            />
          </div>
          <div class="actions">
            <button
              class="link-default actions__btn actions__btn--close"
              @click="closeTotal"
            >
              Закрыть
            </button>
            <button
              :disabled="pending"
              class="actions__btn actions__btn--share link-default"
              @click="onSave"
            >
              Отправить
            </button>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped lang="scss">
.question-board {
  padding: 1rem;
  min-width: 50vw;
  position: fixed;
  z-index: 6;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100dvh;
  align-items: center;
  display: flex;
  background: none;
}

.modal {
  width: 100%;
  margin: auto;
  border-radius: 1.5rem;
  backdrop-filter: blur(80px);
  background: rgba(229, 229, 229, 0.3);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
}

.modal__title {
  padding: 0 30px 13px 30px;
  font-family: Golos;
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.03em;
  text-align: left;
}

.list {
  padding: 0;
  margin: 0;
  max-height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  gap: 13px;
}

.list-item {
  padding: 6px 30px;
  margin: 0;
  display: grid;
  grid-template-rows: 1;
  border-radius: 16px;
  background-color: #ECF2FA;

  .icon {
    transition: all 0.2s ease-out;
  }

  &--opened {

    .icon {
      transform: rotate(180deg);
      transition: all 0.2s ease-in;
    }
  }
}

.rate {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 75% auto;
  padding: 13px 0 13px 30px;
  font-family: Golos;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.03em;
  text-align: left;
}


.list-item__title {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 75% auto;
  gap: 10px;
  font-family: Golos;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.03em;
  text-align: left;
  color: black;
  cursor: pointer;
}

.element {
  display: flex;
  gap: 0.5rem;
}

.actions {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
}

.actions__btn {
  max-width: 100%;
  width: 100%;
  font-size: 1rem;
}

.actions__btn--close {
  background: #ecf2fa;
  color: #8b98aa;
}
</style>
