<template>
  <div class="map-wrapper" ref="mapWrapper">
    <div class="map-content">
      <div class="map">
        <div class="map-info">
          <p class="map-info-title">{{ mapTitle }}</p>
          <label v-if="!isRussia" for="" class="search">
            <img src="@/assets/icons/search.svg" alt="" class="search-icon">
            <input v-model="searchName" type="text" class="search-input" placeholder="Поиск по локациям">
          </label>
          <div class="map-list">
            <Component
                :is="mapCardComponent"
                v-for="(item, index) in currentMapElements?.filter(mapEl=> mapEl?.id && (searchName ? (mapEl.name.toLowerCase().includes(searchName.toLowerCase())) : true))"
                :is-map="true"
                :is-russian="isRussia"
                :current-object="currentObject"
                :map-element="item"
                :locale="locale"
                @openObjectInfo="openObjectInfo"
                :key="index" :index="index"
                @openCurrentObject="openCurrentObject"
                @closeMap="closeMap"
              />
          </div>
        </div>
        <div class="map-container map-desktop" id="map-filling">
          <NewMapElementInfo
            v-if="currentObject"
            :isGame="true"
            :locale="locale"
            :current-object="currentObject"
            @robotGuideEnable="robotGuideEnable"
            @closeInformation="currentObject=null"
            @closeMap="closeMap"
          />
          <MapDraggableInGame :current-object="currentObject" :mapElements="mapElements" ref="map" :locale="locale"
                              :map-scale="mapScale"
                              @openCurrentObject="openCurrentObject" @closeMap="closeMap"></MapDraggableInGame>
          <div class="map-management">
            <div class="zoom">
              <button class="management__element zoom-plus" @click="zoom(true)"></button>
              <button class="management__element zoom-minus" @click="zoom(false)"></button>
            </div>
            <button class="management__element position" v-if="false"></button>
            <button class="management__element size" @click="mapFullScreen"></button>
          </div>
        </div>
        <button class="map__close" @click="closeMap">
          <img src="@/assets/icons/close-main.svg" alt="" class="close-img"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import MapDraggableInGame from "@/components/map/MapDraggableInGame";
import {ref, computed} from "vue";
import MapElementSmall from "@/components/map/MapElementSmall.vue";
import MapElementMedium from "@/components/map/MapElementMedium.vue";
import mapElements from "@/lib/mapElements.json"
import NewMapElementInfo from "@/components/map/NewMapElementInfo.vue";
import {onClickOutside} from "@vueuse/core";
import { useRoute } from "vue-router";
import locations from "@/lib/locations.json";
import allMapElements from "@/lib/data.json"
import russiaMapElements from "@/lib/russia.json"


export default {
  name: "MapComponent",
  components: {NewMapElementInfo, MapDraggableInGame, MapElementSmall, MapElementMedium},
  props: ['locale'],
  emits: [
    'closeMap',
    'openObjectInfo',
    'robotGuideEnable',
  ],
  setup(_props, {emit}) {
    const route = useRoute();
    const isRussia = computed(() =>  route.query?.place?.trim().toLowerCase() === locations.russian?.place?.trim());
    const map = ref(null);
    const mapWrapper = ref(null);
    const mapTitle = computed(() => isRussia.value ? 'Выберите павильон выставки' : 'Локации ВДНХ');

    const mapCardComponent = computed(() => isRussia.value ? MapElementMedium : MapElementSmall);

    const searchName = ref(null);

    const currentObject = ref(null);
    const closeMap = () => emit("closeMap");
    const mapScale = ref(1);
    const zoom = (isPlus) => {
      if (isPlus) {
        if (mapScale.value === 2) return;
        mapScale.value += 0.5;
      } else {
        if (mapScale.value === 0.5) return;
        mapScale.value -= 0.5;
      }
    }
    const mapFullScreen = () => {
      document.fullscreenElement ? document.exitFullscreen() : document.getElementById('map-filling').requestFullscreen();
    }

    const currentMapElements = computed(() => isRussia.value ? russiaMapElements : allMapElements);

    const openCurrentObject = (val) => {
      if (currentObject?.value?.id === val?.id) {
        currentObject.value = null;
        return
      }
      currentObject.value = val;
    }

    onClickOutside(mapWrapper, closeMap);

    return {
      closeMap,
      zoom,
      map,
      mapScale,
      mapWrapper,
      mapFullScreen,
      mapElements,
      allMapElements,
      currentObject,
      openCurrentObject,
      searchName,
      mapTitle,
      isRussia,
      currentMapElements,
      mapCardComponent,

      openObjectInfo: (val) => {
        emit('openObjectInfo', {val, openNavigation: true});
      },
      robotGuideEnable: () => {
        emit('robotGuideEnable');
        closeMap();
      },
    };
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  font-family: "GolosText";
}

.map-wrapper {
  padding: 0 1rem;
  height: calc(100% - 12rem);
  position: absolute;
  z-index: 3;
  max-width: 100%;
  width: 100%;
  display: flex;

}

.map-content {
  padding: 0.63rem;
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 0.5rem;
  background: rgba(157, 196, 248, 0.30);
  backdrop-filter: blur(4px);
  pointer-events: all;
}

.map {
  height: 100%;
  max-width: 100%;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  background: #FFF;
  display: flex;
  gap: 1rem;
}

.map-info {
  max-width: 23.875rem;
  width: 100%;
  padding: 1.5rem 1rem;
  border-radius: 1rem;
  background: #ECF2FA;
  display: flex;
  flex-direction: column;
}

.map-info-title {
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.00875rem;
}

.search {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.69rem 1rem;
  border-radius: 0.5rem;
  background: #FFF;
}

.search-icon {
  display: flex;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.search-input {
  margin-top: 0;
  padding: 0;
  background: none;
  border-radius: 0;
  border: none;
  color: #ABB4C0;
  font-size: 1rem;
}

.map-container {
  display: flex;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.map__close {
  display: flex;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
}

.close-img {
  width: 100%;
  height: 100%;
}

.map-list {
  margin-top: 1.5rem;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  overflow: auto;
}

.map-container {
  padding: 2rem;
  border-radius: 1rem;
  background: #ECF2FA;
  display: flex;
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  align-items: center;
}

.map-management {
  position: absolute;
  right: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
}

.management__element {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
}

.zoom {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.zoom-plus {
  background: url("@/assets/icons/game/zoom-plus.svg") no-repeat center;
  background-size: cover;
}

.zoom-minus {
  background: url("@/assets/icons/game/zoom-minus.svg") no-repeat center;
  background-size: cover;
}

.position {
  background: url("@/assets/icons/game/pozition.svg");
}

.size {
  background: url("@/assets/icons/game/resize.svg") no-repeat center;
  background-size: cover;
  width: 3rem;
  height: 3rem;
}

@media (max-width: 1400px) {
  .management__element {
    width: 2rem;
    height: 2rem;
  }

  .size {
    width: 2rem;
    height: 2rem;
  }

}

@media (max-width: 1000px) {
  .map-wrapper {
    padding: 0 3rem;
    height: calc(100% - 8rem);

  }

  .map-content {
    padding: 0.37rem;
    border-radius: 0.5rem;
  }

  .map {
    padding: 0.5rem;
    border-radius: 0.5rem;
    gap: 0.5rem;
  }

  .map-info {
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  .map-info-title {
    font-size: 0.75rem;
  }

  .map__close {
    width: 1.5rem;
    height: 1.5rem;
  }

  .map-info {
    max-width: 14rem;
  }

  .search {
    margin-top: 0.5rem;
    padding: 0.5rem;
    gap: 0.5rem;
  }

  .search-icon {
    width: 1rem;
    height: 1rem;
  }

  .map-list {
    margin-top: 0.5rem;
  }
}
</style>
