<script setup>
import { defineProps, defineEmits, computed } from "vue";

const props = defineProps(["mapElement", "index", "locale", "isRussian"]);
const emits = defineEmits(["closeMap"]);

const isPassed = computed(() => checkPassed());

function checkPassed() {
  const sessionPassed = sessionStorage.getItem('passed');
  if (sessionPassed) {
    return JSON.parse(sessionPassed)?.includes(props.mapElement.id);
  }

  return false;
}

function onPassed(id) {
  const sessionPassed = sessionStorage.getItem('passed');
  if (!sessionPassed?.length) {
    sessionStorage.setItem('passed', JSON.stringify([id]));
    return;
  }

  const allPassed = JSON.parse(sessionPassed);

  if (!allPassed.includes(id)) {
    allPassed.push(id);
  }

  sessionStorage.setItem('passed', JSON.stringify(allPassed));
}

function teleportToLocation() {
  document.dispatchEvent(
    new CustomEvent("Teleport", {
      detail: props.mapElement.id,
    })
  );
  emits("closeMap");
  onPassed(props.mapElement.id)
}
</script>

<template>
  <div class="element" :class="{ 'element--passed': isPassed }">
    <div class="element-info">
      <img :src="mapElement.imgUrl" alt="" class="element-demonstration" />
      <div class="element-filling">
        <p class="element-subtitle">{{ mapElement.category }}</p>
        <p class="element-title">
          {{ locale === "en" ? mapElement?.name_eng : mapElement?.name }}
        </p>
        <p class="element-description">{{ mapElement.description }}</p>
        <button class="link-teleport" @click="teleportToLocation">
          Перейти к павльону
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.element {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.element--passed {
  opacity: 0.5;
}

.element-info {
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.element-demonstration {
  width: 100%;
  height: 15rem;
  object-fit: cover;
  object-position: center;
  border-radius: 0.25rem;
  background: #d9d9d9;
}

.element-filling {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.element-subtitle {
  color: #abb4c0;
  font-size: 0.75rem;
  letter-spacing: 0.0225rem;
}

.element-title {
  margin-top: 0.25rem;
  color: #000;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.element-description {
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.link-teleport {
  margin-top: 2rem;
  text-align: left;
  font-family: "GolosText";
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.03rem;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #003099;
}

@media (max-width: 1000px) {
  .element-info {
    border-radius: 0.25rem;
    padding: 0.25rem;
    gap: 0.25rem;
  }

  .element-demonstration {
    width: 2.5rem;
    height: 2.5rem;
  }

  .element-subtitle {
    font-size: 0.65rem;
  }

  .element-title {
    font-size: 0.65rem;
  }
}
</style>
