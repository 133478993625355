import {createRouter, createWebHistory} from 'vue-router'
import NewAuthorizationPage from '@/pages/authorization/NewAuthorizationPage'
import NewVideoPage from '@/pages/user/NewVideoPage'
// import NewSettingsPage from '@/pages/user/NewSettingsPage'
import NewLecturesCreationPage from '@/pages/guide/NewLecturesCreationPage'
import NewLecturesListPage from '@/pages/guide/NewLecturesListPage'
import GamePage from '@/pages/general/GamePage'
import {canEnterToUserPortal, checkIsDesktopApp, connectedSSE, setDesktopStatus} from '@/router/helpers'
import DownloadPage from '@/pages/general/DownloadPage.vue'
import NewDateSelectPage from "@/pages/user/NewDateSelectPage.vue";
import {appConfig} from "@/lib/appConfig";

// creating routes to all pages
const routes = [
    // {
    //     path: '/',
    //     name: 'landingPage',
    //     component: LandingPage,
    //     beforeEnter: async (to, from, next) => {
    //
    //         next({name: 'authorizationPage'});
    //
    //         await canEnterToUserPortal(token => {
    //             if (token) {
    //                 next({name: 'mainPage'})
    //             } else {
    //                 next()
    //             }
    //         })
    //     },
    // },
    {
        path: '/',
        name: 'RedesignLandingPage',
        component: () => import('@/pages/general/RedesignLandingPage.vue'),
    },
        {
        path: '/main',
        name: 'RedesignLandingMainPage',
        component: () => import('@/pages/general/RedesignLandingPage.vue'),
    },
    {
        path: '/download',
        name: 'download',
        component: DownloadPage,
    },
    {
        path: '/authorization',
        name: 'authorizationPage',
        component: NewAuthorizationPage,
        beforeEnter: async (to, from, next) => {
            await canEnterToUserPortal(token => {
                if (token) {
                    next({name: 'mainPage'})
                } else {
                    next()
                }
            })
        },
    },
    {
        path: '/guide-authorization',
        name: 'guideAuthorizationPage',
        component: () => import('@/pages/authorization/GuideAuthorizationPage'),
        beforeEnter: async (to, from, next) => {
            await canEnterToUserPortal(token => {
                if (token) {
                    next({name: 'mainPage'})
                } else {
                    next()
                }
            })
        },
    },
    {
        path: '/registration',
        name: 'registrationPage',
        component: () => import('@/pages/authorization/NewRegistrationPage'),
        beforeEnter: async (to, from, next) => {
            if (appConfig.appState.mode === 'test') {
                next({name: 'authorizationPage'})
                return;
            }
            await canEnterToUserPortal(token => {
                if (token) {
                    next({name: 'reMainPage'})
                } else {
                    next()
                }
            })
        },
    },

    {
        path: '/quizskolkovo',
        name: 'quizPage',
        component: () => import('@/pages/quiz/RedesignQuizPage.vue'),
    },

    {
        path: '/cabinet',
        name: 'userCabinet',
        component: () => import('@/pages/user/RedesignUserCabinet'),
        children: [
            {
                path: 'main',
                name: 'mainPage',
                component: () => import('@/pages/personal-area/RedesignProfile.vue'),
            },
            {
                path: 'avatar',
                name: 'avatarPage',
                component: () => import('@/pages/personal-area/RedesignAvatarCreationPage.vue'),
                beforeEnter: async () => {
                    if (!appConfig.isDesktopAppChecked) {
                        const result = await checkIsDesktopApp();
                        setDesktopStatus(result);
                    }
                    connectedSSE(true)
                },
            },
            // {
            //     path: '/map',
            //     name: 'newMapPage',
            //     component: NewMapPage,
            // },
            // {
            //     path: '/calendar',
            //     name: 'calendarPage',
            //     component: NewCalendarPage,
            // },
            {
                path: 'date-select/:id',
                name: 'dateSelect',
                component: NewDateSelectPage,
            },
            {
                path: 'video/:id',
                name: 'videoPage',
                component: NewVideoPage,
            },
            // {
            //     path: 'settings',
            //     name: 'settingsPage',
            //     component: NewSettingsPage,
            // },
            {
                path: 'game',
                name: 'gamePage',
                component: GamePage,
                beforeEnter: async () => {
                    if (!appConfig.isDesktopAppChecked) {
                        const result = await checkIsDesktopApp();
                        setDesktopStatus(result);
                    }
                    connectedSSE()
                },
            },
            {
                path: 'lobby/:id/:slotId?',
                name: 'lobbyPage',
                props: true,
                component: () => import('@/pages/general/LobbyPage.vue'),
            },
            {
                path: 'personal-lobby/:code',
                name: 'personalLobbyPage',
                props: true,
                component: () => import('@/pages/general/PersonalLobbyPage.vue'),
            },
            // {
            //     path: 'avatar-old',
            //     name: 'avatarCreationPage',
            //     component: AvatarCreationPage,
            //     beforeEnter: async () => {
            //         const result = await checkIsDesktopApp();
            //         setDesktopStatus(result);
            //         connectedSSE(true);
            //     },
            // },
            {
                path: '/presentation/:id',
                name: 'presentationEditorPage',
                props: true,
                component: NewLecturesCreationPage,
            },
            // {
            //     path: '/event/editor/:eventId',
            //     name: 'newEventCreation',
            //     props: true,
            //     component: NewEventCreationPage,
            // },
            {
                path: '/presentations',
                name: 'presentationsPage',
                component: NewLecturesListPage,
            },
            // {
            //     path: '/event/placement/:eventId',
            //     name: 'pickPlacement',
            //     component: NewPlaceSelect,
            //     props: true
            // },
        ],
        beforeEnter: async (to, from, next) => {
            await canEnterToUserPortal(token => {
                if (token) {
                    if (!appConfig.isDesktopAppChecked) {
                        checkIsDesktopApp().then((val) => {
                            if (val != null) {
                                setDesktopStatus(val)
                            }
                        });
                    }

                    next()
                } else {
                    localStorage.setItem('redirectRoute', JSON.stringify(to));
                    next({name: 'authorizationPage'})
                }
            })
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        beforeEnter: (to, from, next) => {
            next({name: 'RedesignLandingPage'})
        },
    },
]

// creating Vue-Router instance with normal history mode
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior: function (to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        } else {
            return {top: 0}
        }
    }
})

export default router
