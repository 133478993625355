<script setup>
import { defineProps, defineEmits, onMounted, onUnmounted } from "vue";
import dayjs from "dayjs";

defineProps(['currentSlot']);

const emit = defineEmits(['close']);

onMounted(() => {
  document.body.style.overflow = 'hidden';
});
onUnmounted(() => {
  document.body.style.overflow = '';
});
</script>

<template>
  <div class="shell" @click="emit('close')">
    <div class="modal" @click.stop>
      <div class="modal-caption">
        <img :src="currentSlot?.previewUrl ?? require('@/assets/new-icons/landing/test-data/test-1.png')" alt=""
          class="modal-caption__img">
        <button class="close" @click="emit('close')">
          <img src="" alt="" class="close__icon">
        </button>
      </div>
      <div class="content">
        <p class="content__text">Вы записаны</p>
        <p class="content__title">{{ currentSlot?.title }}</p>
        <p class="content__date">{{ dayjs(currentSlot?.startTime)?.format('DD MMMM в HH:mm') }}</p>
        <p class="content__about">Подробности, как зайти на экскурсию, отправлены на вашу почту. Для подключения
          необходим
          компьютер с интернетом. Скорость интернет-соединения — 20 Мбит. </p>
        <div class="how">
          <div class="how-element">
            <img src="@/assets/new-icons/alarm.svg" alt="" class="how-element__icon">
            <p class="how-element__text">За 10 минут до начала вам на почту придет напоминание о записи. </p>
          </div>
          <div class="how-element">
            <img src="@/assets/new-icons/cursor.svg" alt="" class="how-element__icon">
            <p class="how-element__text">Перейдите по ссылке в письме, чтобы попасть на страницу мероприятия. Нажмите
              кнопку <span>«Начать»</span> и дождитесь старта экскурсии. </p>
          </div>
        </div>
        <p class="content__end">
          Запись на экскурсию будет отображаться на вашей личной странице <a href="https://metamsk.mos.ru/"
            target="_blank">metamsk.mos.ru</a>
          <span class="content__end-mobile">
            Проект работает в пилотном режиме. Экскурсии и прогулки по МетаВДНХ доступны только с компьютера.
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.shell {
  overflow: auto;
}

.modal {
  padding: 0;
  max-width: 39.19rem;
  border-radius: 1.25rem;
  background: #fff;

  &-caption {
    position: relative;
    padding-bottom: 56.25%;
    max-width: 100%;
    width: 100%;
    border-radius: 1.25rem 1.25rem 0 0;

    &__img {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 1.25rem 1.25rem 0 0;
    }
  }


  .close {
    background: center / contain no-repeat url("@/assets/new-icons/close-white.svg") rgba(245, 246, 247, 0.4);
    backdrop-filter: blur(20px);
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.5rem;

    @media (max-width: 1100px) {
      width: 2rem;
      height: 2rem;
    }
  }
}

.content {
  padding: 1.25rem 1.25rem 1.5rem 1.25rem;
}


.content {
  &__text {
    font-size: 1.12rem;
    line-height: 133%;
    text-align: center;
  }

  &__title {
    margin-top: 0.5rem;
    font-weight: 500;
    font-size: 2rem;
    line-height: 130%;
    letter-spacing: 0.01em;
    text-align: center;
    color: #13151a;

    @media (max-width: 1100px) {
      font-size: 1.5rem;
    }
  }

  &__date {
    margin-top: 0.75rem;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 140%;
    text-align: center;
    color: #13151a;

    @media (max-width: 1100px) {
      font-size: 1.25rem;
    }
  }

  &__about {
    font-weight: 400;
    font-size: 1rem;
    line-height: 140%;
    color: #13151a;
  }

  .how {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &-element {
      border-radius: 0.75rem;
      padding: 0.75rem;
      background: #F8F8F9;
      display: flex;
      align-items: flex-start;
      gap: 1rem;
      padding: 0.75rem;

      &__icon {
        width: 3.25rem;
        height: 3.25rem;
      }

      &__text {
        font-weight: 400;
        font-size: 0.88rem;
        line-height: 130%;
        color: #000;
      }
    }
  }

  &__end {
    margin-top: 0.75rem;
    border-radius: 0.75rem;
    font-weight: 400;
    font-size: 0.88rem;
    line-height: 130%;
    color: #000;
    padding: 0.75rem;
    background: #e1eaff;


    &-mobile {
      display: none;
      margin-top: 10px;
      font-weight: 600;
      font-size: 14px;
      color: #E22C38;

      @media (max-width: 767px) {
        display: block;
      }
    }

    a {
      text-decoration: underline;
    }
  }
}
</style>