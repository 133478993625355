export const appConfig = {
    //PRODUCTION
    domain: 'https://back.metamsk.ru',
    frontendDomain: "https://metamsk.ru",
    socket: 'wss://back.metamsk.ru/websocket/',
    stream: 'wss://back.metamsk.ru/websocket/stream',

    //TEST
    // domain: 'https://test-back.metamsk.ru',
    // frontendDomain: "https://test.metamsk.ru",
    // socket: 'wss://test-back.metamsk.ru/websocket/',
    // stream: 'wss://test-back.metamsk.ru/websocket/stream',


    //LOCAL
    // domain: 'http://localhost:3000',
    // frontendDomain: "http://localhost:8080",
    // socket: 'ws://localhost:3001/websocket/',
    // stream: 'ws://localhost:3001/websocket/stream',


    isDesktopApp: false,
    isDesktopAppChecked: false,
    desktopSocket: "ws://localhost:4000/",
    instance: "https://stream.metamsk.ru:9000/signallingserver",


    appState: {
        mode: 'test',
        isLockedSomeActions: true,
        acceptedEmails: [
            'svetlana.lobanova@mail.ru',
            'kirill.kot.70@icloud.com',
            'amirov.and@ya.ru',
            'coo@vrt.one',
            'malyshevrt@gmail.com',
            'vitaliy.klykov@gmail.com',
            'GanzVlad1@gmail.com',
            'maraevv@mail.ru',
            'test.email@test.ru',
            'test.email2@test.ru',
            'test.email3@test.ru',
            "alexey.pichugin@gmail.com",
            "edgar.frolenkov@yandex.ru",
            "Demo@msk.ru",
            "guide@z8.ru",
            "lecture@z8.ru",
            "lector@mail.com",
            "dittest1@mail.ru",
            "dittest2@mail.ru",
            "dittest3@mail.ru",
            "dittest4@mail.ru",
            "dittest5@mail.ru",
            "dittest6@mail.ru",
            "dittest7@mail.ru",
            "dittest8@mail.ru",
            "dittest9@mail.ru",
            "dittest10@mail.ru",
        ]
    }
}

export const maps = {
    russia: '/Game/Assets/Vdnh/Buildings/interior_1_Pavilion_video/L_interior_1_Pavilion_video',
}