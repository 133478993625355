<template>
  <div class="chat-start-wrapper" :class="{ 'disable-actions': trainingStep != null }">
    <div class="chat-element desktop chat-start">
      <p class="persone-notidication" v-if="unreadMessages?.length">
        {{ unreadMessages.length }}
      </p>
      <ExplanationElement v-if="false"></ExplanationElement>
      <button id="chatButton" class="chat-btn open-chat" @click="openChat"
        :class="{ 'open-chat__selected': viewChat || trainingStep === 7 }"></button>
    </div>
  </div>
  <div class="chat-wrapper " :class="{ 'chat-wrapper-open': viewChat }" ref="chatEl">
    <div class="chat-content ">
      <div class="training-bg training-bg--active" v-if="trainingStep != null"></div>
      <div class="head"
        :class="{ 'training-backlight training-backlight--active': trainingStep === 7, 'disable-actions': trainingStep != null }">
        <div class="caption">
          <p class="nearby-title">
            {{ $t('game.chat.usersNear') }}
          </p>
          <button class="close" @click="openChat"></button>
        </div>
        <!-- <div class="nearby" :class="{ 'messages-open': !viewChat }" v-if="chatType !== 'event'">
          <div class="persons" v-if="nearPlayers">
            <button @click="pickDialogue(item)" class="persone"
              :class="{ 'persone-with-nick': receiverId !== item.id && !chatInvite }" v-for="item in nearPlayers.filter(
                (user) => user?.id !== userId
              )" :key="item.id">
              <img :src="getUserAvatar(item)" alt="" class="persone-img" />
              <p class="persone-text" v-if="receiverId !== item.id" :title="item.name">
                {{ item.name?.slice(0, 10) }}
              </p>
            </button>
          </div>
        </div> -->
      </div>
      <div v-if="chatId" class="messages"
        :class="{ 'messages-open': !viewChat, 'disable-actions': trainingStep != null }">
        <div class="messages-caption" v-if="chatType !== 'event'">
          <!-- <button class="back" @click="clearChat" v-if="receiverId && !chatInvite">
            <img src="@/assets/new-icons/small-back.svg" alt="" class="back-img" />
          </button> -->
          <!-- <img :src="getUserAvatar(receiver)" v-if="receiverId && !chatInvite" alt=""
            class="messages-caption-user-face" /> -->
          <!-- TODO Вставить имя -->
          <!-- <p class="messages-caption__name" v-if="false">Taисия Я.</p> -->
          <!-- <button class="messages-caption-call"
            :class="{ 'training-backlight training-backlight--active': trainingStep === 8 }"
            v-if="trainingStep === 8 || (receiverId && !userCall && !((receiver?.receiverId ? receiver?.receiverId : receiver?.id) === receiver?.userId) && !chatInvite)"
            @click="callUp"></button>
          <button class="messages-caption-call messages-caption-call__delete"
            :class="{ blocked: receiverId !== userCall.peer }" :title="receiverId !== userCall.peer
              ? $t('game.chat.call')
              : ''
              " v-if="receiverId && userCall" @click="dropCall"></button> -->
          <!-- TODO Вставить дату -->
          <!-- <p class="messages-caption__time" v-if="false">Сегодня</p> -->
        </div>
        <div class="messages-list">
          <div class="messages-list-content">
            <SeparateMessage :get-user-avatar="getUserAvatar" v-for="( item, index ) in
              messagesList.sort(
                (a, b) => new Date(a.createDate) - new Date(b.createDate)
              )
" :key="index" :message="item" :id="`msg${index + 1}`" :my-avatar="myAvatar" v-show="messagesList?.length">
            </SeparateMessage>
            <p class="no-dialog" v-show="!messagesList?.length">
              {{ $t('game.chat.noMessages') }}
            </p>
            <!-- <ChatVote v-if="!isLectureOrGuide" /> -->
          </div>
        </div>
      </div>
      <!-- <div class="messages-list-dialogs-wrapper" v-if="!receiverId || chatInvite">
        <p class="dialogs-caption">
          Последняя переписка:
        </p>
        <div class="messages-list messages-list-dialogs">
          <SeparateDialog v-show="dialogues?.length" :get-user-avatar="getUserAvatar" @pickDialogue="pickDialogue"
            v-for="( dialogue, index ) in
              dialogues.sort(
                (a, b) => new Date(b.createDate) - new Date(a.createDate)
              )
            " :key="index" :unread-messages="unreadMessages.filter(
              (message) =>
                message.userId ===
                (dialogue.self ? dialogue.receiverId : dialogue.userId)
            )
              " :dialogue="dialogue"></SeparateDialog>
          <p class="no-dialog" v-show="!dialogues?.length">{{ $t('game.chat.allChats') }}</p>
        </div>
      </div> -->
      <div class="complaint desktop" v-if="false">
        <div class="complaint-info">
          <img src="@/assets/icons/complaint-info.svg" alt="" class="complaint-icon">
          <p class="complaint-text">Заблокировать пользователя</p>
        </div>
        <button class="complaint-btn">
          <img src="@/assets/icons/alert.svg" alt="" class="complaint-icon">
          <p class="complaint-text">Пожаловаться</p>
        </button>
      </div>
      <!-- <VoteAdmin v-if="isLectureOrGuide" :eventId="eventId" /> -->
      <div v-if="chatId" class="chat-filling" :class="{ 'chat-open': viewChat }">
        <input type="text" :placeholder="$t('game.chat.wright')" @keydown.enter="sendNewMessage" v-model="newMessage"
          class="chat__input" :maxlength="MESSAGE_LIMIT" @focusin="lockedKeyboard(true)" @blur="lockedKeyboard(false)"
          @input.stop />
        <div class="smile-wrapper">
          <emoji-picker id="smileWindow" :native="true" @select="pickEmoji" v-if="viewEmoji" :disable-skin-tones="true"
            class="smiles">
          </emoji-picker>
          <button class="smile" id="smileBtn" @click="viewEmoji = !viewEmoji"></button>
        </div>
        <button class="send" @click="sendNewMessage"></button>
      </div>
    </div>
  </div>
</template>

<script>
import ExplanationElement from "./ExplanationElement.vue";
import SeparateMessage from "./SeparateMessage.vue";
// import SeparateDialog from "./SeparateDialog.vue";
// import VoteAdmin from "./VoteAdmin.vue";
// import ChatVote from "./ChatVote.vue";
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/css";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { onClickOutside } from "@vueuse/core";

export default {
  name: "ChatWrapper",
  components: {
    ExplanationElement,
    SeparateMessage,
    EmojiPicker,
    // SeparateDialog,
    // VoteAdmin,
    // ChatVote,
  },
  props: [
    "viewChat",
    "nearPlayers",
    "userId",
    "eventId",
    "receiverId",
    "userCall",
    "myAvatar",
    "chatInvite",
    "role",
    "trainingStep"
  ],
  emits: [
    'closeInviteChat',
    'openChat',
    'callUp',
  ],

  setup(props, { emit }) {
    const store = useStore();
    const newMessage = ref("");
    const route = useRoute();
    const chatId = ref(props.receiverId ?? '');
    const MESSAGE_LIMIT = 300;
    const viewEmoji = ref(false);
    const vote = computed(() => store.getters.vote);
    const messagesList = computed(() => store.getters.messagesList);
    const unreadMessages = computed(() => store.getters.unreadMessages);
    const dialogues = computed(() => store.getters.dialogues);
    const chatType = computed(() => store.getters.chatType);
    const receiver = computed(() => {
      const index = dialogues.value
        .map((item) => (item.self ? item.receiverId : item.userId))
        .indexOf(props.receiverId);
      if (index > -1) {
        return dialogues.value[index];
      }
      const index2 = props.nearPlayers
        .map((item) => item.id)
        .indexOf(props.receiverId);
      if (index2 > -1) {
        return props.nearPlayers[index2];
      }
      return null;
    });

    const chatEl = ref(null);

    const getUserAvatar = (user) => {
      return user?.avatarUrl ?? require("@/assets/face-poster.svg");
    };

    watch(
      () => messagesList.value.length,
      (val) => {
        emit('closeInviteChat');
        setTimeout(() => {
          document.getElementById(`msg${val}`)?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }, 100);
      }
    );
    watch(() => props.viewChat,
      (val) => {
        store.commit('resetUnreadMessages');
        if (val) {
          setTimeout(() => {
            document.getElementById(`msg${messagesList.value.length}`)?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }, 100);
        }
      }
    );
    watch(() => props.receiverId, (val) => {
      if (val) {
        store.dispatch("fetchMessages");
      }
    }
    );

    const openChat = () => emit("openChat");
    const sendNewMessage = () => {
      if (!newMessage.value) return;
      store
        .dispatch("sendNewMessage", {
          body: newMessage.value,
          receiver: chatType.value === 'event' ? null : receiver.value,
        })
        .then(() => {
          newMessage.value = "";
          viewEmoji.value = false;
        });
    };
    const pickEmoji = (emoji) =>
    (newMessage.value +=
      newMessage.value?.length >= MESSAGE_LIMIT ? "" : emoji?.i);
    const callUp = () => {
      emit("openChat");
      emit("callUp");
    };
    const dropCall = () => store.dispatch("dropCall");

    const clearChat = () => store.commit("clearChat");

    const pickDialogue = (user) => {
      if (props.receiverId === (user?.id ? user.id : (user.self ? user?.receiverId : user?.userId))) {
        clearChat();
        return;
      }
      store.commit("setChatParams", {
        receiverId: (user?.id ? user.id : (user.self ? user?.receiverId : user?.userId)),
        chatType: "user",
        receiverName: (user?.id ? user.name : (user.self ? user?.receiverName : user?.name))

      });
    };

    const lockedKeyboard = (val) => {
      if (val) {
        store.commit('resetUnreadMessages');
      }
      // if (chatType.value === 'event' && (props.role !== 'guide' && props.role !== 'lecture')) return;
      document?.dispatchEvent(new CustomEvent('LockedKeyboard', { detail: val }));
    }

    const closeSmiles = (event) => {

      if (event?.target?.id === 'smileBtn' || document?.getElementById('smileWindow')?.contains(event?.target)) return;
      viewEmoji.value = false
    }

    watch(() => viewEmoji.value, (val) => {
      if (!val) {
        document.removeEventListener('click', closeSmiles)
      }
      if (val) {
        document.addEventListener('click', closeSmiles)
      }
    })

    const isLectureOrGuide = computed(() => ['guide', 'lecture'].includes(props.role));

    onMounted(() => {
      console.log('CHAT_PROPS: ', props);
      console.log('SESSION CHAT_ID', sessionStorage.getItem('slotId'))
      console.log('ROUTE', route)
      if (!chatId.value?.length) {
        chatId.value = sessionStorage.getItem('slotId') ?? route.query.slotId;
      }
    })

    onBeforeUnmount(() => {
      if (props.userCall) {
        dropCall();
      }
      store.commit("clearDialogues");
    });


    watch(newMessage, (value, previousValue) => {
      if (!previousValue.length && value.length) {
        store.commit('resetUnreadMessages');
      }
    });

    onClickOutside(chatEl, ({ target }) => {
      if (!props.viewChat || document.getElementById('chatButton')?.contains(target)) return;
      openChat();
    })




    //sessionStorage.getItem('slotId')

    return {
      viewEmoji,
      newMessage,
      messagesList,
      dialogues,
      unreadMessages,
      MESSAGE_LIMIT,
      receiver,
      chatType,
      chatEl,
      chatId,

      openChat,
      sendNewMessage,
      pickDialogue,
      pickEmoji,
      callUp,
      dropCall,
      clearChat,
      getUserAvatar,
      lockedKeyboard,
      vote,

      isLectureOrGuide,
    };
  },
};
</script>

<style scoped>
.chat-wrapper {
  position: fixed;
  right: 1rem;
  bottom: 5.5rem;
  display: flex;
  flex-direction: column;
  max-width: 28.25rem;
  pointer-events: none;
  z-index: 8;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  height: 75vh;
  height: 75dvh;
  display: flex;
}

.chat-content {
  padding: 1rem 1.25rem;
  max-width: 100%;
  width: 100%;
  border-radius: 1.25rem;
  backdrop-filter: blur(80px);
  background: rgba(157, 196, 248, 0.3);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.nearby {
  margin-top: 1rem;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  width: 100%;
  position: relative;
}

.nearby-wrapper {
  max-width: 100%;
  width: 100%;
}

.nearby-content {
  margin-top: 5px;
  display: flex;
  padding: 4px 0px 4px 11px;
  border-radius: 0.9375rem;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.16) 0%,
      rgba(255, 255, 255, 0.16) 100%),
    rgba(12, 107, 242, 0.46);
  backdrop-filter: blur(22.5px);
  align-items: center;
  max-width: 100%;
  width: 100%;
}

.persons-wrapper {
  display: flex;
  max-width: 100%;
  width: 100%;
  padding: 0 14px;
  overflow: hidden;
}

.persons {
  display: flex;
  padding: 1rem;
  max-width: 100%;
  width: 100%;
  overflow: auto;
  align-items: center;
  gap: 1rem;
  scrollbar-width: thin;
  border: 0.50px solid rgba(255, 255, 255, 0.6);
  border-radius: 0.5rem;
  backdrop-filter: blur(40px);
  background: rgba(229, 229, 229, 0.2);
}

.nearby-title {
  font-weight: 600;
  font-size: 1.5rem;
  color: #fff;
}


.persone {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}

.persone-notidication {
  position: absolute;
  right: -7px;
  top: -2px;
  font-size: 10px;
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  justify-content: center;
  border-radius: 100%;
  color: #fff;
  background: #e51740;
  z-index: 2;
}

.persone-img {
  display: flex;
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  object-position: center;
  border-radius: 100%;
}


.persone-text {
  max-width: 4.5rem;
  margin-top: 0.25rem;
  font-weight: 600;
  font-size: 0.62rem;
  text-align: center;
  color: #fff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: anywhere;
  -webkit-line-clamp: 1;
  word-break: break-all;
}

.caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.close {
  width: 1.5rem;
  height: 1.5rem;
  background: url("@/assets/new-icons/close-defoalt.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-shrink: 0;
}

.chat-conent {
  margin-top: 20px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1599999964237213);
  border-radius: 24px;
  backdrop-filter: blur(45px);
  padding: 10px 16px;
  gap: 10px;
}

.chat-element {
  display: flex;
  flex-shrink: 0;
  background: #ffffff4d;
  border-radius: 100%;
  backdrop-filter: blur(45px);
  width: 3rem;
  height: 3rem;

  position: relative;
}

.chat-start-wrapper {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  padding: 0.5rem;
  border-radius: 100%;
  background: rgba(157, 196, 248, 0.30);
  backdrop-filter: blur(4px);
}


.emoji {
  background: url("@/assets/icons/emoji.svg");
}

.share {
  background: url("@/assets/icons/share.svg");
}

.open-chat {
  background: url("@/assets/icons/open-chat.svg");
}

.open-chat__selected {
  background: url("@/assets/icons/open-chat__open.svg");
}

.blue-chat {
  background: url("@/assets/icons/game/blue-chat.svg");
}

.chat-btn {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: relative;
}

.complaint {
  margin-top: 0.7rem;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.complaint-info {
  display: flex;
  align-items: center;
}

.complaint-icon {
  display: flex;
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
}

.complaint-text {
  margin-left: 0.5rem;
  color: #AAC0DA;
  font-size: 0.75rem;
  font-weight: 400;
}

.complaint-btn {
  display: flex;
  align-items: center;
}

.chat-filling {
  position: relative;
  margin-top: 1rem;
  display: none;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background: #fff;
  max-width: 100%;
  width: 100%;
}

.chat-open {
  display: flex;
}

.chat-close {
  background: url("@/assets/icons/chat-close.svg");
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  flex-shrink: 0;
  margin-right: 10px;
}

.chat__input {
  padding: 0;
  max-width: 100%;
  width: 100%;
  border: none;
  color: #5d6c85;
  font-size: 1rem;
  line-height: 140%;
  margin-top: 0;
  background: none;
  border-radius: 0;
}

.chat__input::placeholder {
  opacity: 1;
}

.smile-wrapper {
  margin-left: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  flex-shrink: 0;
  position: relative;
}

.smiles {
  position: absolute;
  left: -16rem;
  top: -20.5rem;
}

.smile {
  width: 100%;
  height: 100%;
  background: url("@/assets/icons/smile.svg");
}

.send {
  margin-left: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  background: url("@/assets/icons/send.svg");
  background-size: cover;
  display: flex;
  flex-shrink: 0;
}

/* messages */
.messages {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  background: #ECF2FA;
  overflow: hidden;
  padding: 1rem;
  border: 0.50px solid rgba(255, 255, 255, 0.6);
  border-radius: 0.5rem;
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  background: rgba(229, 229, 229, 0.4);
}

.messages-caption {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}

.messages-caption__text {
  color: #000;
  font-size: 0.875rem;
  font-weight: 500;
}


.back {
  display: flex;
  align-items: center;
}

.back-img {
  width: 1.5rem;
  height: 1.5rem;
}

.messages-caption-user-face {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}

.messages-caption__name {
  font-weight: 600;
  font-size: 0.88rem;
  color: #fff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: anywhere;
  -webkit-line-clamp: 1;
  word-break: break-all;
}

.messages-caption__time {
  margin-left: auto;
  font-weight: 600;
  font-size: 0.62rem;
  text-align: right;
  color: rgba(255, 255, 255, 0.8);
}

.back-text {
  color: #5D6C85;
  font-size: 0.75rem;
}

.messages-caption-call {
  display: flex;
  flex-shrink: 0;
  background-image: url("@/assets/icons/game/call.svg");
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
}

.messages-caption-call__delete {
  display: flex;
  flex-shrink: 0;
  background: url("@/assets/icons/game/drop-call.svg");
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
}

.messages-caption__close {
  width: 24px;
  height: 24px;
  background: url("@/assets/icons/close-small.svg");
  background-size: cover;
}

.messages-list-wrapper {
  height: 100%;
  display: flex;
}

.messages-list {
  margin-top: 0.75rem;
  display: flex;
  height: 100%;
  padding: 1rem;
  border: 0.50px solid rgba(255, 255, 255, 0.6);
  border-radius: 0.5rem;
  backdrop-filter: blur(40px);
  background: rgba(229, 229, 229, 0.4);
}

.messages-list-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  gap: 0.5rem;
  max-height: 100%;
  overflow: auto;
  padding-right: .5rem;
}

.messages-list-content::-webkit-scrollbar-track {
  border-radius: .5rem;
}

.messages-list-content::-webkit-scrollbar-thumb {
  border-radius: .5rem;
}

.messages-list--first {
  padding: 0;
  background: none;
  border: none;
  backdrop-filter: none;
  border-radius: 0;
}

.messages-list-dialogs-wrapper {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.dialogs-caption {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
}

.no-dialog {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin: auto;
}

.chat-wrapper-open {
  pointer-events: all;
  opacity: 1;
}

.messages-open {
  display: none;
}

.training-bg {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0.5rem;
  background: rgba(157, 196, 248, 0.30);
  backdrop-filter: blur(4px);
  height: 100%;
  width: 100%;
  z-index: -1;
  pointer-events: none;
  transition: all 0.5s ease;
}

.training-bg--active {
  z-index: 2;
  pointer-events: all;
  opacity: 1;
}

.head {
  position: relative;
  transition: all 0.5s ease;

}

.head--training {
  padding: 0.5rem;
  background: #fff;
  z-index: 3;

  box-shadow: 0px 8px 4px -4px rgba(0, 48, 153, 0.45);
}


@media (max-width: 1400px) {
  .smile {
    background-position: center;
  }

  .smiles {
    top: -23.5rem;
  }
}

@media (max-width: 1000px) {
  .desktop {
    display: none;
  }

  .chat-wrapper {
    height: 80vh;
    height: 80dvh;
    max-width: 20rem;
    width: 100%;
    display: flex;
    right: -20rem;
    bottom: 1rem;
    padding: 0.38rem;
    transition: all 1s ease-in-out;
    border-radius: 0.5rem;
    z-index: 6;
  }

  .chat-start-wrapper {
    display: none;
  }

  .chat-wrapper-open {
    right: 1rem;
  }

  .chat-content {
    padding: 1rem;
  }

  .nearby-title {
    font-size: 0.75rem;
  }

  .close {
    width: 1.5rem;
    height: 1.5rem;
  }

  .nearby {
    margin-top: 0.5rem;
  }

  .persons {
    padding: 0.5rem;
    border-radius: 0.5rem;
    gap: 0.5rem;
  }

  .persone-img {
    width: 2rem;
    height: 2rem;
  }

  .persone-text {
    margin-top: 0.25rem;
    font-size: 0.625rem;
  }

  .messages-list-dialogs-wrapper {
    margin-top: 0.5rem;
  }

  .chat__input {
    font-size: 0.75rem;
  }

  .dialogs-caption {
    font-size: 0.75rem;
  }

  .messages {
    margin-top: 0.81rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  .messages-caption {
    padding: 0.25rem 0;
  }

  .back {
    gap: 0.5rem;
  }

  .back-img {
    width: 1rem;
    height: 1rem;
  }

  .back-text {
    font-size: 0.625rem;
  }

  .messages-caption-call {
    width: 1.5rem;
    height: 1.5rem;
  }

  .messages-caption-user-face {
    width: 2rem;
    height: 2rem;
  }

  .messages-list {
    margin-top: 0.5rem;
  }

  .chat-filling {
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
  }

  .chat-close {
    width: 1rem;
    height: 1rem;
    background-size: contain;
    margin-right: 0.5rem;
  }

  .smile-wrapper {
    width: 1rem;
    height: 1rem;
  }

  .smile {

    background-size: contain;
  }

  .send {
    width: 1rem;
    height: 1rem;
    background-size: contain;
    margin-left: 0.25rem;
  }

  .vote-creation {
    width: 17.3rem;
    padding: 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0.5rem;
    bottom: 1.38rem;
  }

  .vote__title {
    font-size: 0.75rem;
  }

  .vote-start__img {
    width: 1rem;
    height: 1rem;
  }

  .vote__close {
    width: 1rem;
    height: 1rem;
  }

  .vote-viewing__list {
    width: 1rem;
    height: 1rem;
  }

  .vote__text {
    font-size: 0.6rem;
  }

  .vote__input {
    padding: 0.5rem;
    font-size: 0.75rem;
    font-size: 0.65rem;
    border-radius: 0.25rem;
  }

  .vote__element-delete {
    font-size: 0.6rem;
  }

  .vote-start__text {
    font-size: 0.75rem;
  }

  .vote__add {
    font-size: 0.6rem;
  }

  .vote__send {
    margin-top: 0.5rem;
    padding: 0.5rem;
    font-size: 0.75rem;
    border-radius: 0.25rem;
  }

  .vote-start__bg {
    bottom: -0.2rem;
    width: calc(100% + 0.5rem);
    height: calc(100% + 0.6rem);
    border-radius: 0 0 0.25rem 0.25rem;
  }

  .vote-viewing {
    width: 17.3rem;
    padding: 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0.5rem;
    bottom: 1.38rem;
    right: 0;
  }

  .answers {
    margin-top: 0.5rem;
  }

  .vote-viewing__subtitle {
    font-size: 0.6rem;
  }

  .answers__title {
    font-size: 0.6rem;
  }

  .answer {
    padding: 0.3rem 0.5rem;
  }

  .answer__text {
    font-size: 0.6rem;
  }

  .answer__percent {
    font-size: 0.6rem;
  }

  .check {
    margin-top: 0;
    padding: 0 0 0 1.25rem;
    font-size: 0.6rem;
  }

  .check__box {
    width: 0.75rem;
    height: 0.75rem;
  }
}

.training-backlight {
  position: relative;
  transition: all 0.5s ease;
}

.training-backlight--active {
  z-index: 8;
  background-color: #fff;
  border-radius: 1rem;
  padding: 1rem;
}
</style>
